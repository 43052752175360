import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment-timezone';

// context
// import { useAuth } from 'context/AuthContext';
import { useSelector } from 'react-redux';

// components
import ApplicationDetails from 'views/admin/users/components/ApplicationDetails';

// services
import { handleGetInitiatedProperties } from '../../../services/data.service';
import { getUserApplication } from 'services/applications.service';
import { getHomebuyerById } from 'services/applications.service';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Flex,
	Text,
	Spinner,
	useToast,
} from '@chakra-ui/react';

const InitiatedProperties = () => {
	const { allApplications, initiatedProperties } = useSelector(
		(state) => state.user
	);

	const [initiatedPropertiesList, setInitiatedPropertiesList] = useState([]);

	const [selectedUser, setSelectedUser] = useState(null);
	const [applicationData, setApplicationData] = useState();
	const [storageData, setStorageData] = useState();
	const [storageLoading, setStorageLoading] = useState(false);

	const [loading, setLoading] = useState(false);
	const [selectionLoading, setSelectionLoading] = useState(false);
	const [dialogLoading, setDialogLoading] = useState(false);
	const [userName, setUserName] = useState('');

	// const { searchResults } = useAuth();
	const { initiatedOffers } = useSelector((state) => state.offer);
	const [deleteId, setDeleteId] = useState(null);
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: loadingOpen,
		onOpen: onLoadingOpen,
		onClose: onLoadingClose,
	} = useDisclosure();

	const closeModal = () => {
		setSelectedUser(null);
	};

	const getStorageData = async (userId) => {
		// try {
		// 	setStorageLoading(true);
		// 	let storageRes = await getUserFilesById(userId);
		// 	if (
		// 		storageRes.backgroundVerification?.length === 0 &&
		// 		storageRes.bankStatements?.length === 0 &&
		// 		storageRes.incomeVerification.length === 0
		// 	) {
		// 		storageRes = await getHomebuyerFilesById(userId);
		// 	}
		// 	setStorageData(storageRes);
		// } catch (err) {
		// 	toast({
		// 		title: 'Error',
		// 		description:
		// 			'Something went wrong while getting user files. Please try again',
		// 		status: 'error',
		// 		duration: 4000,
		// 		isClosable: true,
		// 		position: 'top-right',
		// 	});
		// } finally {
		// 	setStorageLoading(false);
		// }
	};

	useEffect(() => {
		const fetchData = async () => {
			// Sort data in reverse chronological order based on createdAt
			// data.sort((a, b) => {
			// 	const dateA = new Date(
			// 		a.createdAt.seconds * 1000 +
			// 			a.createdAt.nanoseconds / 1000000
			// 	);
			// 	const dateB = new Date(
			// 		b.createdAt.seconds * 1000 +
			// 			b.createdAt.nanoseconds / 1000000
			// 	);
			// 	return dateB - dateA;
			// });

			setInitiatedPropertiesList(initiatedProperties);
		};

		fetchData().catch(console.error);
	}, []);

	const handleSelectRow = async (rowData) => {
		try {
			setSelectionLoading(true);
			onLoadingOpen();
			const [user, application] = await Promise.all([
				getHomebuyerById(rowData.clientId),
				getUserApplication(rowData.clientId),
			]);
			getStorageData(rowData?.clientId);
			const appStepSeven = application?.stepSeven;

			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			setApplicationData(application);
			setSelectedUser({
				...user,
				application,
				name: username,
				firstName,
				lastName,
			});
		} catch (err) {
			toast({
				title: 'Error',
				description:
					'Unable to open the application at the moment. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		} finally {
			setSelectionLoading(false);
			onLoadingClose();
		}
	};

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<TableContainer>
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Initated By</Th>
								<Th>Client Email</Th>
								<Th>Property Address</Th>
								<Th>Property List Price</Th>
								<Th>Date</Th>
								<Th>Action</Th>
							</Tr>
						</Thead>
						<Tbody>
							{initiatedPropertiesList.map((property) => (
								<Tr>
									<Td>Landlord</Td>
									<Td>{property?.userData?.email}</Td>
									<Td>
										{
											property?.propertyData?.stepTwo[3]
												.answer
										}
									</Td>
									<Td>
										{
											property?.propertyData?.stepThree[0]
												.answer
										}
									</Td>
									<Td>
										{moment
											.tz(
												property?.createdAt?.toDate(),
												'America/New_York'
											)
											.format('MM/DD/YYYY hh:mm A')}
									</Td>
									<Td>
										<Button
											onClick={async () =>
												await handleSelectRow({
													clientId: property?.userId,
												})
											}
										>
											View
										</Button>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>

			<ApplicationDetails
				closeModal={closeModal}
				selectedUser={selectedUser}
				isOpen={Boolean(selectedUser)}
				loading={storageLoading}
				applicationData={applicationData}
				storageData={storageData}
				setSelectedUser={setSelectedUser}
				setApplicationData={setApplicationData}
				setStorageData={setStorageData}
				allAplications={allApplications}
				getStorageData={getStorageData}
			/>
		</Fragment>
	);
};

export default InitiatedProperties;
