import {
	ArrowBackIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
} from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useToast,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { getCoapplicantData_Optimized } from 'services/applications.service';
import { handleGetInitiatedPropertiesByUserId } from 'services/data.service';
// import { ChevronRightIcon } from "react-icons";

import wordsToNumbers from 'words-to-numbers';
import ApplicationTab from './applicationDetailTabs/ApplicationTab';
import DocumentsTab from './applicationDetailTabs/DocumentsTab';
import ManagmentTab from './applicationDetailTabs/ManagmentTab';
import OffersTab from './applicationDetailTabs/OffersTab';
import PropertiesInitiatedTab from './applicationDetailTabs/PropertiesInitiatedTab';
import PropertyModal from 'views/admin/properties/components/PropertyModal';

const ApplicationDetails = ({
	closeModal,
	isOpen,
	selectedUser,
	loading,
	applicationData,
	storageData,
	setSelectedUser,
	setApplicationData,
	setStorageData,
	allAplications,
	getStorageData,
}) => {
	const {
		isOpen: propertyModalIsOpen,
		onOpen: propertyModalOnOpen,
		onClose: propertyModalOnClose,
	} = useDisclosure();
	const [initiatedProperties, setInitiatedProperties] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState(null);

	const [applicationDetails, setApplicationDetails] = useState([]);
	const [coApplicantDetails, setCoapplicantDetails] = useState([]);

	const [originalCoapplicantData, setOriginalCoapplicantData] =
		useState(null);
	const [origCoappDisplayData, setOrigCoappDisplayData] = useState([]);

	const modalBodyRef = useRef(null);

	const toast = useToast();

	const isTimestamp = (value) => {
		try {
			value.toDate();
			return true;
		} catch (err) {
			return false;
		}
	};

	const [
		currentApplicationIndex,
		hasNextApplication,
		hasPreviousApplication,
	] = useMemo(() => {
		const index = allAplications.findIndex(
			(application) => application.id === selectedUser?.id
		);
		const hasNextApplication = index < allAplications.length - 1;

		const hasPreviousApplication = index > 0;
		return [index, hasNextApplication, hasPreviousApplication];
	}, [allAplications, selectedUser]);

	const goToNextAplication = () => {
		setSelectedUser(null);
		setStorageData(null);
		setOriginalCoapplicantData(null);
		setOrigCoappDisplayData([]);

		const nextApplication = allAplications[currentApplicationIndex + 1];
		setSelectedUser(nextApplication);
		setApplicationData(nextApplication?.application);
		// getStorageData(nextApplication?.id);
	};

	const goToPreviousAplication = () => {
		setSelectedUser(null);
		setStorageData(null);
		setOriginalCoapplicantData(null);
		setOrigCoappDisplayData([]);

		const nextApplication = allAplications[currentApplicationIndex - 1];
		setSelectedUser(nextApplication);
		setApplicationData(nextApplication?.application);
		// getStorageData(nextApplication?.id);
	};

	const displayData = useMemo(() => {
		if (!applicationData) {
			return [];
		}

		const finalData = Object.keys(applicationData)
			.filter((key) => key.includes('step'))
			.map((key) => {
				const name = key.split('step')[1];
				const stepName = wordsToNumbers(name);
				let displayName = `Step ${stepName}`;
				let questions = applicationData[key];

				if (stepName === 7) {
					displayName = `Step ${stepName}  Credit verification`;
					questions = questions.map((step) => ({
						...step,
						question: step.question?.replace(
							'Credit verification -',
							''
						),
					}));
				} else if (stepName === 10) {
					displayName = `Step ${stepName}  Employer details `;
					const [q1, q2, ...rest] = questions;
					const answer = isTimestamp(q2.answer)
						? moment(q2.answer.toDate())
								.tz('America/New_York')
								.format('YYYY-MM-DD')
						: q2.answer;
					questions = [{ ...q1 }, { ...q2, answer }, ...rest].map(
						(step) => ({
							...step,
							question: step.question?.replace(
								'Employer details -',
								''
							),
						})
					);
				} else if (stepName === 16) {
					displayName = `Step ${stepName}  Background Check `;
					questions = questions.map((step) => ({
						...step,
						question: step.question?.replace(
							'Background Check -',
							''
						),
					}));
				} else if (stepName === 17) {
					displayName = `Step ${stepName}  `;
					const [q1, q2, ...rest] = questions;
					const answer = isTimestamp(q2.answer)
						? moment(q2.answer.toDate())
								.tz('America/New_York')
								.format('YYYY-MM-DD')
						: q2.answer;
					questions = [{ ...q1 }, { ...q2, answer }, ...rest];
				}

				return {
					name,
					displayName,
					data: questions,
					step: stepName,
				};
			})
			.sort((a, b) => a.step - b.step);

		setApplicationDetails(finalData);

		return finalData;
	}, [applicationData, selectedUser]);

	const loadCoapplicantData = async () => {
		try {
			let data = [];

			const response = await getCoapplicantData_Optimized(
				selectedUser.id
			);

			if (!response) return;

			setOriginalCoapplicantData(response);

			for (let key of Object.keys(response)) {
				const name = key.split('step')[1];

				if (name) {
					let stepName = wordsToNumbers(name);
					let displayName = `Step ${stepName}`;

					let questions = response[key];

					if (Array.isArray(questions)) {
						data.push({
							name: name,
							displayName: displayName,
							data: questions,
							step: stepName,
						});
					}
				}
			}

			const finalData = data.sort((a, b) => a.step - b.step);

			setCoapplicantDetails(finalData);

			syncStepsAndQuestions(displayData, finalData);
		} catch (err) {
			toast({
				title: 'Something went wrong',
				description:
					'Something wen wrong while getting coapplicant details. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		}
	};

	const syncStepsAndQuestions = (applicantData, coapplicantData) => {
		const maxSteps = Math.max(
			applicationDetails?.length ?? 0,
			coApplicantDetails?.length ?? 0
		);

		const newApplicationDetails = [...applicantData];
		const newCoApplicantDetails = [...coapplicantData];

		for (let i = 0; i < maxSteps; i++) {
			const appStep = newApplicationDetails[i];
			const coAppStep = newCoApplicantDetails[i];

			if (!appStep) {
				newApplicationDetails.push({ ...coAppStep, data: [] });
			}

			if (!coAppStep) {
				newCoApplicantDetails.push({ ...appStep, data: [] });
			}

			const maxQuestions = Math.max(
				appStep?.data?.length ?? 0,
				coAppStep?.data?.length ?? 0
			);

			const newAppStepData = [...(appStep?.data ?? [])];
			const newCoAppStepData = [...(coAppStep?.data ?? [])];

			for (let j = 0; j < maxQuestions; j++) {
				const appQuestion = newAppStepData[j];
				const coAppQuestion = newCoAppStepData[j];

				if (appQuestion?.question === '' && !coAppQuestion) {
					// Both appQuestion and coAppQuestion are empty, skip adding dummy question
					continue;
				}

				if (!appQuestion) {
					newAppStepData.push({ question: '', answer: '' });
				}

				if (!coAppQuestion) {
					newCoAppStepData.push({ ...appQuestion, answer: '' });
				}
			}

			newApplicationDetails[i] = {
				...newApplicationDetails[i],
				data: newAppStepData,
			};
			newCoApplicantDetails[i] = {
				...newCoApplicantDetails[i],
				data: newCoAppStepData,
			};
		}

		setApplicationDetails(newApplicationDetails);
		setCoapplicantDetails(newCoApplicantDetails);
		setOrigCoappDisplayData(newCoApplicantDetails);
	};

	// useEffect(() => {

	// }, []);

	useEffect(() => {
		// setCoapplicantDetails(null);
		// if (selectedUser) {
		//   getStorageData(selectedUser?.id);
		//   loadCoapplicantData();
		// }

		let timeoutId;

		setCoapplicantDetails(null);
		// setSelectedUser(null);
		setStorageData(null);
		setOriginalCoapplicantData(null);
		setOrigCoappDisplayData([]);

		// Clear any previously set timeouts to avoid running the effect multiple times
		clearTimeout(timeoutId);

		// Set a new timeout to run the effect after 1 second (1000 milliseconds)
		timeoutId = setTimeout(() => {
			if (selectedUser) {
				getStorageData(selectedUser.id);
				loadCoapplicantData();
			}
		}, 1000);

		// Clean up the timeout on unmount or when selectedUser changes
		return () => clearTimeout(timeoutId);
	}, [selectedUser]);

	const email = selectedUser?.email;

	const HAS_COAPPLICANT =
		displayData[4]?.data[1]?.answer ||
		displayData[4]?.data[2]?.answer ||
		displayData[4]?.data[3]?.answer ||
		displayData[4]?.data[4]?.answer ||
		coApplicantDetails;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await handleGetInitiatedPropertiesByUserId(
					selectedUser.id
				);

				let formattedData = [];
				for (let property of data) {
					console.log({ property });
					formattedData.push({
						id: property.id,
						userType: property.userData.type,
						email: property.userData.email,
						propertyAddress:
							property.propertyData.stepTwo[3].answer,
						propertyListPrice:
							property.propertyData.stepThree[0].answer,
						createdAt: new Date(
							property?.createdAt?.seconds * 1000 +
								property?.createdAt?.nanoseconds / 1000000
						).toLocaleDateString(),
						propertyData: property?.propertyData
							? { id: property.id, ...property?.propertyData }
							: null,
					});
				}

				formattedData.sort((a, b) => {
					const dateA = new Date(
						a.createdAt.seconds * 1000 +
							a.createdAt.nanoseconds / 1000000
					);
					const dateB = new Date(
						b.createdAt.seconds * 1000 +
							b.createdAt.nanoseconds / 1000000
					);
					return dateB - dateA;
				});

				console.log('formattedData', formattedData);

				setInitiatedProperties(formattedData);
			} catch (err) {
				console.log('Error fetching initiated properties: ', err);
				console.log([]);
			}
		};

		fetchData().catch(console.error);
	}, [selectedUser]);

	return (
		<Modal
			isCentered
			size="full"
			onClose={closeModal}
			isOpen={isOpen}
			motionPreset="scale"
			scrollBehavior="inside"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader scrollSnapType={'none'}>
					<Flex
						marginTop={-4}
						alignItems={'center'}
						justifyContent={'space-between'}
					>
						<IconButton
							variant="ghost"
							aria-label="Close Modal"
							icon={<ArrowBackIcon boxSize={6} />}
							onClick={closeModal}
						/>

						<Flex alignItems={'center'}>
							<Text
								marginRight={2}
								fontSize={18}
							>
								{currentApplicationIndex + 1} of{' '}
								{allAplications?.length}
							</Text>
							<IconButton
								variant="ghost"
								aria-label="previous"
								icon={<ChevronLeftIcon boxSize={8} />}
								onClick={goToPreviousAplication}
								disabled={!hasPreviousApplication || loading}
							/>

							<IconButton
								variant="ghost"
								aria-label="next"
								icon={<ChevronRightIcon boxSize={8} />}
								onClick={goToNextAplication}
								disabled={!hasNextApplication || loading}
							/>
						</Flex>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Box flex={1}>
							<Text fontSize="2xl">Buyer</Text>
							<Text fontSize="xl">{selectedUser?.name}</Text>
							<Text
								fontWeight="medium"
								fontSize="lg"
							>
								{email}
							</Text>
							<Text
								fontWeight="medium"
								fontSize="lg"
							>
								{displayData && displayData[0]?.data[0]?.answer}
							</Text>
							{applicationData && (
								<Text
									fontWeight="medium"
									fontSize="lg"
									color={
										selectedUser?.applicationStatus ===
										'Completed'
											? 'green.400'
											: 'red.500'
									}
								>
									{selectedUser?.applicationStatus}
								</Text>
							)}
						</Box>

						{HAS_COAPPLICANT && displayData ? (
							<>
								<Box
									borderWidth={1}
									mx={5}
								/>

								<Box
									flex={1}
									maxW={'45vw'}
								>
									<Text fontSize="2xl">Co-Buyer</Text>
									<Text fontSize="xl">
										{displayData[4]?.data[1]?.answer}{' '}
										{displayData[4]?.data[2]?.answer}
									</Text>
									<Text
										fontWeight="medium"
										fontSize="lg"
									>
										{displayData[4]?.data[3]?.answer}
									</Text>
									<Text
										fontWeight="medium"
										fontSize="lg"
									>
										{displayData[4]?.data[4]?.answer}
									</Text>

									{originalCoapplicantData && (
										<Text
											fontWeight="medium"
											fontSize="lg"
											color={
												originalCoapplicantData?.applicationCompleted
													? 'green.400'
													: 'red.500'
											}
										>
											{originalCoapplicantData?.applicationCompleted
												? 'Completed'
												: 'Inprogress'}
										</Text>
									)}
								</Box>
							</>
						) : (
							<></>
						)}
						{/* {hasNextApplication && (
              <Box
                cursor={"pointer"}
                onClick={goToNextAplication}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Text fontSize={14}>GO TO NEXT</Text>
                <Icon as={BsChevronCompactRight} width="30px" height="30px" />
              </Box>
            )} */}
					</Flex>
				</ModalHeader>
				{/* <ModalCloseButton marginTop={5} /> */}
				<ModalBody
					scrollBehavior="smooth"
					ref={modalBodyRef}
				>
					<Tabs>
						<TabList>
							<Tab
								_focus={{ outline: 'none' }}
								flex={1}
							>
								Application
							</Tab>
							<Tab
								_focus={{ outline: 'none' }}
								flex={1}
							>
								Documents
							</Tab>
							<Tab
								_focus={{ outline: 'none' }}
								flex={1}
							>
								Offers
							</Tab>
							<Tab
								_focus={{ outline: 'none' }}
								flex={1}
							>
								Properties Initiated
							</Tab>
							<Tab
								_focus={{ outline: 'none' }}
								flex={1}
							>
								Managment
							</Tab>
						</TabList>

						<TabPanels>
							<TabPanel>
								<ApplicationTab
									applicationData={applicationData}
									closeModal={closeModal}
									displayData={displayData}
									coApplicantDetails={coApplicantDetails}
									applicationDetails={applicationDetails}
									setApplicationDetails={
										setApplicationDetails
									}
									setCoApplicantDetails={
										setCoapplicantDetails
									}
									selectedUser={selectedUser}
									originalCoapplicantData={
										origCoappDisplayData
									}
									coapplicantApplication={
										originalCoapplicantData
									}
								/>
							</TabPanel>

							<TabPanel>
								<DocumentsTab
									selectedUser={selectedUser}
									loading={loading}
									applicationData={applicationData}
									storageData={storageData}
									hasCoapplicant={HAS_COAPPLICANT}
								/>
							</TabPanel>

							<TabPanel>
								<OffersTab selectedUser={selectedUser} />
							</TabPanel>

							<TabPanel>
								<PropertiesInitiatedTab
									selectedUser={selectedUser}
								/>
							</TabPanel>

							<TabPanel>
								<ManagmentTab
									selectedUser={selectedUser}
									applicationData={applicationData}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="blue"
						mr={3}
						onClick={closeModal}
					>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default memo(ApplicationDetails);
