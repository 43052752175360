import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	users: [],
	allApplications: [],
	pendingApplications: [],
	approvedApplications: [],
	rejectedApplications: [],
	moreInfoRequestApplications: [],
	startedApplications: [],
	otheredApplications: [],
	allSubmittedApplications: [],
	inReviewApplications: [],
	pausedApplications: [],
	conditionallyApproved: [],
	offerExtended: [],
	offerAccepted: [],
	inspectionComplete: [],
	preclosingComplete: [],
	offerClosed: [],
	inactive: [],
	dateRange_users: [],
	dateRange_allApplications: [],
	dateRange_pendingApplications: [],
	dateRange_approvedApplications: [],
	dateRange_rejectedApplications: [],
	dateRange_moreInfoRequestApplications: [],
	dateRange_startedApplications: [],
	dateRange_otheredApplications: [],
	dateRange_allSubmittedApplications: [],
	dateRange_inReviewApplications: [],
	dateRange_pausedApplications: [],
	dateRange_conditionallyApproved: [],
	dateRange_offerExtended: [],
	dateRange_offerAccepted: [],
	dateRange_inspectionComplete: [],
	dateRange_preclosingComplete: [],
	dateRange_offerClosed: [],
	dateRange_inactive: [],
	waitlistUsers: [],
	landlords: [],
	properties: [],
	inReviewProperties: [],
	rejectedProperties: [],
	acceptedProperties: [],
	initiatedProperties: [],
};

export const UserSlice = createSlice({
	name: 'User',
	initialState,
	reducers: {
		resetAdminState: (state) => initialState,
		setUsers: (state, action) => {
			state.allApplications = action.payload?.all;
			state.pendingApplications = action.payload?.pending;
			state.approvedApplications = action.payload?.approved;
			state.rejectedApplications = action.payload?.rejected;
			state.moreInfoRequestApplications = action.payload?.moreInfo;
			state.startedApplications = action.payload?.started;
			state.otheredApplications = action.payload?.othered;
			state.allSubmittedApplications = action.payload?.allSubmitted;
			state.inReviewApplications = action.payload?.inReview;
			state.pausedApplications = action.payload?.paused;

			//new

			state.conditionallyApproved = action.payload.conditionallyApproved;
			state.offerExtended = action.payload.offerExtended;
			state.offerAccepted = action.payload.offerAccepted;
			state.inspectionComplete = action.payload.inspectionComplete;
			state.preclosingComplete = action.payload.preclosingComplete;
			state.offerClosed = action.payload.offerClosed;
			state.inactive = action.payload.inactive;
			state.waitlistUsers = action.payload.waitlistUsers;
			state.landlords = action.payload.landlords;
			state.properties = action.payload.properties;
			state.inReviewProperties = action.payload.inReviewProperties;
			state.rejectedProperties = action.payload.rejectedProperties;
			state.acceptedProperties = action.payload.acceptedProperties;
			state.initiatedProperties = action.payload.initiatedProperties;
		},
		setDateRangeUsers: (state, action) => {
			state.dateRange_allApplications = action.payload?.all;
			state.dateRange_pendingApplications = action.payload?.pending;
			state.dateRange_approvedApplications = action.payload?.approved;
			state.dateRange_rejectedApplications = action.payload?.rejected;
			state.dateRange_moreInfoRequestApplications =
				action.payload?.moreInfo;
			state.dateRange_startedApplications = action.payload?.started;
			state.dateRange_otheredApplications = action.payload?.othered;
			state.dateRange_allSubmittedApplications =
				action.payload?.allSubmitted;
			state.dateRange_inReviewApplications = action.payload?.inReview;
			state.dateRange_pausedApplications = action.payload?.paused;
			state.dateRange_conditionallyApproved =
				action.payload.conditionallyApproved;
			state.dateRange_offerExtended = action.payload.offerExtended;
			state.dateRange_offerAccepted = action.payload.offerAccepted;
			state.dateRange_inspectionComplete =
				action.payload.inspectionComplete;
			state.dateRange_preclosingComplete =
				action.payload.preclosingComplete;
			state.dateRange_offerClosed = action.payload.offerClosed;
			state.dateRange_inactive = action.payload.inactive;
		},
	},
});

export const { resetAdminState, setUsers, setDateRangeUsers } =
	UserSlice.actions;

export default UserSlice.reducer;
