import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { handleGetInitiatedPropertiesByUserId } from 'services/data.service';
import PropertyModal from 'views/admin/properties/components/PropertyModal';

// chakra
import {
	Box,
	Button,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useToast,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	useDisclosure,
} from '@chakra-ui/react';
import {
	ArrowBackIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
} from '@chakra-ui/icons';

const PropertiesInitiatedTab = ({ selectedUser }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [initiatedProperties, setInitiatedProperties] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await handleGetInitiatedPropertiesByUserId(
					selectedUser.id
				);

				let formattedData = [];
				for (let property of data) {
					console.log({ property });
					formattedData.push({
						id: property.propertyId,
						userType: property.userData.type,
						email: property.userData.email,
						propertyAddress:
							property.propertyData.stepTwo[3].answer,
						propertyListPrice:
							property.propertyData.stepThree[0].answer,
						createdAt: property?.createdAt,
						propertyData: property?.propertyData
							? {
									id: property.propertyId,
									...property?.propertyData,
							  }
							: null,
					});
				}

				formattedData.sort((a, b) => {
					return b.createdAt - a.createdAt;
				});

				setInitiatedProperties(formattedData);
			} catch (err) {
				console.log('Error fetching initiated properties: ', err);
				console.log([]);
			}
		};

		fetchData().catch(console.error);
	}, [selectedUser]);

	return (
		<Fragment>
			<Box>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>Initated By</Th>
							<Th>Client Email</Th>
							<Th>Property Address</Th>
							<Th>Property List Price</Th>
							<Th>Date</Th>
							<Th>View</Th>
						</Tr>
					</Thead>
					<Tbody>
						{initiatedProperties.map((property) => (
							<Tr>
								<Td>{property?.userType}</Td>
								<Td>{property?.email}</Td>
								<Td>{property?.propertyAddress}</Td>
								<Td>{property?.propertyListPrice}</Td>
								<Td>
									{moment
										.tz(
											property?.createdAt?.toDate(),
											'America/New_York'
										)
										.format('MM/DD/YYYY hh:mm A')}
								</Td>
								<Td>
									<Button
										variant="ghost"
										onClick={() => {
											console.log({
												property,
											});
											setSelectedProperty(
												property?.propertyData
											);

											onOpen();
										}}
									>
										View
									</Button>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>

			{selectedProperty && (
				<PropertyModal
					isOpen={isOpen}
					onClose={onClose}
					selectedProperty={selectedProperty}
					setSelectedProperty={setSelectedProperty}
				/>
			)}
		</Fragment>
	);
};

export default PropertiesInitiatedTab;
