import React, { Fragment, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// services
import {
	updatePropertyApplicationDetails,
	getPropertyApplicationFilesByApplicationId,
	getUserById,
	getAgentDataById,
	getLandlordDataById,
	sendApprovedEmails,
} from 'services/applications.service';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	Flex,
	Container,
	Stack,
	Text,
	Input,
	StackDivider,
	Image,
	Select,
	FormControl,
	FormLabel,
	FormErrorMessage,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Textarea,
	FormHelperText,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Spinner,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const PropertyModal = ({
	isOpen,
	onClose,
	selectedProperty,
	setSelectedProperty,
}) => {
	const [loading, setLoading] = useState(false);
	const [filesLoading, setFilesLoading] = useState(false);

	const [applicationUserData, setApplicationUserData] = useState(null);

	const [propertyStatus, setPropertyStatus] = useState(null);

	const [stepOnePropertyType, setStepOnePropertyType] = useState(null);
	const [stepOneNumberOfBedrooms, setStepOneNumberOfBedrooms] =
		useState(null);
	const [stepOneNumberOfBathrooms, setStepOneNumberOfBathrooms] =
		useState(null);
	const [stepOneMonthlyRent, setStepOneMonthlyRent] = useState(null);
	const [stepOneSquareFeet, setStepOneSquareFeet] = useState(null);
	const [stepOneYearBuilt, setStepOneYearBuilt] = useState(null);
	const [stepOneLotSize, setStepOneLotSize] = useState(null);

	const [stepTwoPropertyState, setStepTwoPropertyState] = useState(null);
	const [stepTwoPropertyMetro, setStepTwoPropertyMetro] = useState(null);
	const [stepTwoPropertyCity, setStepTwoPropertyCity] = useState(null);
	const [stepTwoPropertyAddress, setStepTwoPropertyAddress] = useState(null);

	const [stepThreeValueOfProperty, setStepThreeValueOfProperty] =
		useState(null);
	const [stepThreeReasonForValue, setStepThreeReasonForValue] =
		useState(null);

	const [stepFourPropertyDescription, setStepFourPropertyDescription] =
		useState(null);

	const [stepFiveSalesCmaDocs, setStepFiveSalesCmaDocs] = useState([]);
	const [stepFiveAppraisalReportDocs, setStepFiveAppraisalReportDocs] =
		useState([]);

	const [stepSixPropertyImages, setStepSixPropertyImages] = useState([]);

	const [stepSevenLandlordFirstName, setStepSevenLandlordFirstName] =
		useState(null);
	const [stepSevenLandlordLastName, setStepSevenLandlordLastName] =
		useState(null);
	const [stepSevenLandlordEmail, setStepSevenLandlordEmail] = useState(null);
	const [stepSevenLandlordPhone, setStepSevenLandlordPhone] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setPropertyStatus(selectedProperty?.status);

				setStepOnePropertyType(selectedProperty?.stepOne[0]?.answer);
				setStepOneNumberOfBedrooms(
					selectedProperty?.stepOne[1]?.answer
				);
				setStepOneNumberOfBathrooms(
					selectedProperty?.stepOne[2]?.answer
				);
				setStepOneMonthlyRent(selectedProperty?.stepOne[3]?.answer);
				setStepOneSquareFeet(selectedProperty?.stepOne[4]?.answer);
				setStepOneYearBuilt(selectedProperty?.stepOne[5]?.answer);
				setStepOneLotSize(selectedProperty?.stepOne[6]?.answer);

				setStepTwoPropertyState(selectedProperty?.stepTwo[0]?.answer);
				setStepTwoPropertyMetro(selectedProperty?.stepTwo[1]?.answer);
				setStepTwoPropertyCity(selectedProperty?.stepTwo[2]?.answer);
				setStepTwoPropertyAddress(selectedProperty?.stepTwo[3]?.answer);

				setStepThreeValueOfProperty(
					selectedProperty?.stepThree[0]?.answer
				);
				setStepThreeReasonForValue(
					selectedProperty?.stepThree[1]?.answer
				);

				setStepFourPropertyDescription(
					selectedProperty?.stepFour[0]?.answer
				);

				setStepSevenLandlordFirstName(
					selectedProperty?.stepSeven[0]?.answer
				);
				setStepSevenLandlordLastName(
					selectedProperty?.stepSeven[1]?.answer
				);
				setStepSevenLandlordEmail(
					selectedProperty?.stepSeven[2]?.answer
				);
				setStepSevenLandlordPhone(
					selectedProperty?.stepSeven[3]?.answer
				);

				setFilesLoading(true);
				const files = await getPropertyApplicationFilesByApplicationId(
					selectedProperty?.userId,
					selectedProperty?.id
				);

				let salesCmaDocs = [];
				for (let file of files?.salesCmaDocs) {
					salesCmaDocs.push(file);
				}
				setStepFiveSalesCmaDocs(salesCmaDocs);

				let appraisalReportDocs = [];
				for (let file of files?.appraisalReportDocs) {
					appraisalReportDocs.push(file);
				}
				setStepFiveAppraisalReportDocs(appraisalReportDocs);

				let propertyImages = [];
				for (let file of files?.propertyImages) {
					propertyImages.push(file);
				}
				setStepSixPropertyImages(propertyImages);
				setFilesLoading(false);
			} catch (err) {
				console.log(err);
				setFilesLoading(false);
			}
		};

		fetchData().catch(console.error);
	}, [selectedProperty]);

	useEffect(() => {
		const fetchData = async () => {
			if (selectedProperty?.userId) {
				const userData = await getUserById(selectedProperty?.userId);
				if (userData?.type === 'agent') {
					const agentData = await getAgentDataById(
						selectedProperty?.userId
					);

					setApplicationUserData({
						type: userData?.type,
						firstName: userData?.legalName?.firstName,
						lastName: userData?.legalName?.lastName,
						phone: agentData?.stepOne[0]?.answer,
						email: userData?.email,
					});
				} else if (userData?.type === 'landlord') {
					const landlordData = await getLandlordDataById(
						selectedProperty?.userId
					);

					setApplicationUserData({
						type: userData?.type,
						firstName: landlordData?.stepOne[0]?.answer,
						lastName: landlordData?.stepOne[1]?.answer,
						phone: landlordData?.stepOne[2]?.answer,
						email: landlordData?.email,
					});
				}
			}
		};

		fetchData().catch(console.error);
	}, []);

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					setSelectedProperty(null);
					onClose();
				}}
				size="full"
			>
				<ModalOverlay />
				<Formik
					initialValues={{
						propertyStatus: propertyStatus,
						stepOnePropertyType: stepOnePropertyType,
						stepOneNumberOfBedrooms: stepOneNumberOfBedrooms,
						stepOneNumberOfBathrooms: stepOneNumberOfBathrooms,
						stepOneMonthlyRent: stepOneMonthlyRent,
						stepOneSquareFeet: stepOneSquareFeet,
						stepOneYearBuilt: stepOneYearBuilt,
						stepOneLotSize: stepOneLotSize,
						stepTwoPropertyState: stepTwoPropertyState,
						stepTwoPropertyMetro: stepTwoPropertyMetro,
						stepTwoPropertyCity: stepTwoPropertyCity,
						stepTwoPropertyAddress: stepTwoPropertyAddress,
						stepThreeValueOfProperty: stepThreeValueOfProperty,
						stepThreeReasonForValue: stepThreeReasonForValue,
						stepFourPropertyDescription:
							stepFourPropertyDescription,
						stepSevenLandlordFirstName:
							stepSevenLandlordFirstName || '',
						stepSevenLandlordLastName:
							stepSevenLandlordLastName || '',
						stepSevenLandlordEmail: stepSevenLandlordEmail || '',
						stepSevenLandlordPhone: stepSevenLandlordPhone || '',
					}}
					validationSchema={yup.object({
						propertyStatus: yup.string().required('Required'),
						stepOnePropertyType: yup.string().required('Required'),
						stepOneNumberOfBedrooms: yup
							.string()
							.required('Required'),
						stepOneNumberOfBathrooms: yup
							.string()
							.required('Required'),
						stepOneMonthlyRent: yup
							.number()
							.min(
								100,
								'Property must have a monthly rent higher that $100'
							)
							.max(
								1000000,
								'Currently taking properties with a monthly rent less that $1,000,000'
							)
							.required('Required'),
						stepOneSquareFeet: yup
							.number()
							.min(10, 'Square feet must be at least 10')
							.max(100000, 'Square feet must be at most 100,000')
							.required('Required'),
						stepOneYearBuilt: yup
							.number()
							.min(1900, 'Must be greater than 1900')
							.max(
								new Date().getFullYear(),
								'Cannot be greater than current year'
							)
							.required('Required'),
						stepOneLotSize: yup
							.number()
							.max(1000000, 'Lot size must be at most 1,000,000')
							.required('Required'),
						stepTwoPropertyState: yup.string().required('Required'),
						stepTwoPropertyMetro: yup.string().required('Required'),
						stepTwoPropertyCity: yup.string(),
						stepTwoPropertyAddress: yup
							.string()
							.min(5, 'Must be at least 5 characters long')
							.required('Required'),
						stepThreeValueOfProperty: yup
							.number()
							.min(
								100000,
								'We are currently only taking homes with a value at or greater than $100,000'
							)
							.max(
								1000000,
								'We are currently only taking homes with a value at or less than $1,000,000'
							)
							.required('Required'),
						stepThreeReasonForValue: yup
							.string()
							.min(
								50,
								'Your response should at least be 50 characters long'
							)
							.required('Required'),
						stepFourPropertyDescription: yup
							.string()
							.min(
								50,
								'Your response should at least be 50 characters long'
							)
							.required('Required'),
						stepSevenLandlordFirstName: yup.string().optional(),
						stepSevenLandlordLastName: yup.string().optional(),
						stepSevenLandlordEmail: yup
							.string()
							.email('Invalid email address')
							.optional(),
						stepSevenLandlordPhone: yup
							.string()
							.optional()
							.matches(
								/^\d{10}$/,
								'Phone number must be 10 digits'
							),
					})}
					onSubmit={async (values) => {
						try {
							setLoading(true);
							await updatePropertyApplicationDetails(
								selectedProperty?.id,
								{
									propertyStatus: values.propertyStatus,
									stepOne: [
										{
											question:
												selectedProperty?.stepOne[0]
													?.question,
											answer: values.stepOnePropertyType,
										},
										{
											question:
												selectedProperty?.stepOne[1]
													?.question,
											answer: values.stepOneNumberOfBedrooms,
										},
										{
											question:
												selectedProperty?.stepOne[2]
													?.question,
											answer: values.stepOneNumberOfBathrooms,
										},
										{
											question:
												selectedProperty?.stepOne[3]
													?.question,
											answer: values.stepOneMonthlyRent,
										},
										{
											question:
												selectedProperty?.stepOne[4]
													?.question,
											answer: values.stepOneSquareFeet,
										},
										{
											question:
												selectedProperty?.stepOne[5]
													?.question,
											answer: values.stepOneYearBuilt,
										},
										{
											question:
												selectedProperty?.stepOne[6]
													?.question,
											answer: values.stepOneLotSize,
										},
									],
									stepTwo: [
										{
											question:
												selectedProperty?.stepTwo[0]
													?.question,
											answer: values.stepTwoPropertyState,
										},
										{
											question:
												selectedProperty?.stepTwo[1]
													?.question,
											answer: values.stepTwoPropertyMetro,
										},
										{
											question:
												selectedProperty?.stepTwo[2]
													?.question,
											answer: values.stepTwoPropertyCity,
										},
										{
											question:
												selectedProperty?.stepTwo[3]
													?.question,
											answer: values.stepTwoPropertyAddress,
										},
									],
									stepThree: [
										{
											question:
												selectedProperty?.stepThree[0]
													?.question,
											answer: values.stepThreeValueOfProperty,
										},
										{
											question:
												selectedProperty?.stepThree[1]
													?.question,
											answer: values.stepThreeReasonForValue,
										},
									],
									stepFour: [
										{
											question:
												selectedProperty?.stepFour[0]
													?.question,
											answer: values.stepFourPropertyDescription,
										},
									],
									stepSeven: [
										{
											question:
												selectedProperty?.stepSeven[0]
													?.question,
											answer: values.stepSevenLandlordFirstName,
										},
										{
											question:
												selectedProperty?.stepSeven[1]
													?.question,
											answer: values.stepSevenLandlordLastName,
										},
										{
											question:
												selectedProperty?.stepSeven[2]
													?.question,
											answer: values.stepSevenLandlordEmail,
										},
										{
											question:
												selectedProperty?.stepSeven[3]
													?.question,
											answer: values.stepSevenLandlordPhone,
										},
									],
								}
							);

							if (
								selectedProperty?.status !== 'ACCEPTED' &&
								values.propertyStatus === 'ACCEPTED'
							) {
								await sendApprovedEmails(
									selectedProperty?.userId,
									selectedProperty?.id
								);
							}

							setLoading(true);

							setSelectedProperty(null);
							onClose();
						} catch (err) {
							console.log(err);
							setLoading(true);
						}
					}}
				>
					{(formik) => (
						<form onSubmit={formik.handleSubmit}>
							<ModalContent>
								<ModalHeader>Edit Property</ModalHeader>
								<ModalCloseButton />

								<ModalBody>
									<Stack
										spacing="10"
										divider={
											<StackDivider borderColor="gray.200" />
										}
									>
										{applicationUserData?.type ===
										'agent' ? (
											<Box>
												<Text
													fontWeight="600"
													fontSize="lg"
													mb="2"
												>
													Agent Information
												</Text>

												<Stack spacing="3">
													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Agent First Name
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.firstName
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Agent Last Name
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.lastName
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Agent Phone
																Number
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.phone
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Email
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.email
																}
															</Text>
														</Box>
													</Box>
												</Stack>
											</Box>
										) : applicationUserData?.type ===
										  'landlord' ? (
											<Box>
												<Text
													fontWeight="600"
													fontSize="lg"
													mb="2"
												>
													Landlord Information
												</Text>

												<Stack spacing="3">
													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Landlord First
																Name
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.firstName
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Landlord Last
																Name
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.lastName
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Landlord Phone
																Number
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.phone
																}
															</Text>
														</Box>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<Box>
															<FormLabel color="gray">
																Landlord Email
															</FormLabel>
															<Text
																fontSize="lg"
																fontWeight="500"
															>
																{
																	applicationUserData?.email
																}
															</Text>
														</Box>
													</Box>
												</Stack>
											</Box>
										) : null}

										<Box>
											<FormControl
												isInvalid={
													formik.errors
														.propertyStatus &&
													formik.touched
														.propertyStatus
												}
											>
												<FormLabel color="gray">
													Status
												</FormLabel>
												<Field
													as={Select}
													borderWidth="2px"
													size="lg"
													id="propertyStatus"
													name="propertyStatus"
													placeholder="Select Property Type"
												>
													<option value="IN_REVIEW">
														In Review
													</option>
													<option value="ACCEPTED">
														Accepted
													</option>
													<option value="REJECTED">
														Rejected
													</option>
												</Field>
												<FormErrorMessage>
													{
														formik.errors
															.propertyStatus
													}
												</FormErrorMessage>
											</FormControl>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 1:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOnePropertyType &&
															formik.touched
																.stepOnePropertyType
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[0]
																	?.question
															}
														</FormLabel>
														<Field
															as={Select}
															borderWidth="2px"
															size="lg"
															id="stepOnePropertyType"
															name="stepOnePropertyType"
															placeholder="Select Property Type"
														>
															<option value="single-family home">
																Single-family
																home
															</option>
															<option value="multi-family home">
																Multi-family
																home
															</option>
															<option value="condominium">
																Condominium
															</option>
															<option value="apartment">
																Apartment
															</option>
															<option value="mobile/manufactured home">
																Mobile/Manufactured
																home
															</option>
															<option value="townhouse">
																Townhouse
															</option>
															<option value="dorm/room/student housing">
																Dorm/Room/Student
																housing
															</option>
															<option value="other">
																Other
															</option>
														</Field>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOnePropertyType
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneNumberOfBedrooms &&
															formik.touched
																.stepOneNumberOfBedrooms
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[1]
																	?.question
															}
														</FormLabel>
														<Field
															as={Select}
															borderWidth="2px"
															size="lg"
															id="stepOneNumberOfBedrooms"
															name="stepOneNumberOfBedrooms"
															placeholder="Select number of bedrooms"
														>
															<option value="studio">
																Studio
															</option>
															<option value="1">
																1
															</option>
															<option value="2">
																2
															</option>
															<option value="3">
																3
															</option>
															<option value="4">
																4
															</option>
															<option value="5">
																5
															</option>
															<option value="6">
																6
															</option>
															<option value="7">
																7
															</option>
															<option value="8">
																8
															</option>
															<option value="9">
																9
															</option>
															<option value="10">
																10
															</option>
															<option value="10+">
																10+
															</option>
														</Field>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneNumberOfBedrooms
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneNumberOfBathrooms &&
															formik.touched
																.stepOneNumberOfBathrooms
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[2]
																	?.question
															}
														</FormLabel>
														<Field
															as={Select}
															borderWidth="2px"
															size="lg"
															id="stepOneNumberOfBathrooms"
															name="stepOneNumberOfBathrooms"
															placeholder="Select number of bedrooms"
														>
															<option value="1">
																1
															</option>
															<option value="1.5">
																1.5
															</option>
															<option value="2">
																2
															</option>
															<option value="2.5">
																2.5
															</option>
															<option value="3">
																3
															</option>
															<option value="3.5">
																3.5
															</option>
															<option value="4">
																4
															</option>
															<option value="4.5">
																4.5
															</option>
															<option value="5">
																5
															</option>
															<option value="5.5">
																5.5
															</option>
															<option value="6">
																6
															</option>
															<option value="6+">
																6+
															</option>
														</Field>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneNumberOfBathrooms
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneMonthlyRent &&
															formik.touched
																.stepOneMonthlyRent
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[3]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<InputLeftAddon>
																$
															</InputLeftAddon>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepOneMonthlyRent"
																name="stepOneMonthlyRent"
																type="number"
																min={100}
																max={1000000}
															/>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneMonthlyRent
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneSquareFeet &&
															formik.touched
																.stepOneSquareFeet
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[4]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<InputLeftAddon>
																$
															</InputLeftAddon>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepOneSquareFeet"
																name="stepOneSquareFeet"
																type="number"
																min={10}
																max={100000}
															/>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneSquareFeet
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneYearBuilt &&
															formik.touched
																.stepOneYearBuilt
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[5]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<InputLeftAddon>
																$
															</InputLeftAddon>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepOneYearBuilt"
																name="stepOneYearBuilt"
																type="number"
																min={1900}
																max={new Date().getFullYear()}
															/>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneYearBuilt
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepOneLotSize &&
															formik.touched
																.stepOneLotSize
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepOne[6]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepOneLotSize"
																name="stepOneLotSize"
																type="number"
																max={1000000}
															/>
															<InputRightAddon>
																sqft
															</InputRightAddon>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepOneLotSize
															}
														</FormErrorMessage>
													</FormControl>
												</Box>
											</Stack>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 2:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepTwoPropertyState &&
															formik.touched
																.stepTwoPropertyState
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepTwo[0]
																	?.question
															}
														</FormLabel>
														<Field
															as={Input}
															borderWidth="2px"
															size="lg"
															id="stepTwoPropertyState"
															name="stepTwoPropertyState"
															type="text"
														/>
														<FormErrorMessage>
															{
																formik.errors
																	.stepTwoPropertyState
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepTwoPropertyMetro &&
															formik.touched
																.stepTwoPropertyMetro
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepTwo[1]
																	?.question
															}
														</FormLabel>
														<Field
															as={Input}
															borderWidth="2px"
															size="lg"
															id="stepTwoPropertyMetro"
															name="stepTwoPropertyMetro"
															type="text"
														/>
														<FormErrorMessage>
															{
																formik.errors
																	.stepTwoPropertyMetro
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepTwoPropertyCity &&
															formik.touched
																.stepTwoPropertyCity
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepTwo[2]
																	?.question
															}
														</FormLabel>
														<Field
															as={Input}
															borderWidth="2px"
															size="lg"
															id="stepTwoPropertyCity"
															name="stepTwoPropertyCity"
															type="text"
														/>
														<FormErrorMessage>
															{
																formik.errors
																	.stepTwoPropertyCity
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepTwoPropertyAddress &&
															formik.touched
																.stepTwoPropertyAddress
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepTwo[3]
																	?.question
															}
														</FormLabel>
														<Field
															as={Input}
															borderWidth="2px"
															size="lg"
															id="stepTwoPropertyAddress"
															name="stepTwoPropertyAddress"
															type="text"
															placeholder="Eg. 1234 Main St, Anytown, CA 91234 or https://www.zillow.com/homedetails/1234-Main-St-Anytown-CA-91234/1234567890"
															min={5}
														/>
														<FormHelperText>
															Minimum of 5
															characters long
														</FormHelperText>
														<FormErrorMessage>
															{
																formik.errors
																	.stepTwoPropertyAddress
															}
														</FormErrorMessage>
													</FormControl>
												</Box>
											</Stack>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 3:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepThreeValueOfProperty &&
															formik.touched
																.stepThreeValueOfProperty
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepThree[0]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<InputLeftAddon>
																$
															</InputLeftAddon>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepThreeValueOfProperty"
																name="stepThreeValueOfProperty"
																type="number"
																min={100000}
																max={1000000}
															/>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepThreeValueOfProperty
															}
														</FormErrorMessage>
													</FormControl>
												</Box>

												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepThreeReasonForValue &&
															formik.touched
																.stepThreeReasonForValue
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepThree[1]
																	?.question
															}
														</FormLabel>
														<Field
															as={Textarea}
															borderWidth="2px"
															size="lg"
															id="stepThreeReasonForValue"
															name="stepThreeReasonForValue"
															type="text"
															min={50}
														/>
														<FormErrorMessage>
															{
																formik.errors
																	.stepThreeReasonForValue
															}
														</FormErrorMessage>
													</FormControl>
												</Box>
											</Stack>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 4:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<FormControl
														isInvalid={
															formik.errors
																.stepFourPropertyDescription &&
															formik.touched
																.stepFourPropertyDescription
														}
													>
														<FormLabel color="gray">
															{
																selectedProperty
																	?.stepFour[0]
																	?.question
															}
														</FormLabel>
														<InputGroup size="lg">
															<InputLeftAddon>
																$
															</InputLeftAddon>
															<Field
																as={Textarea}
																borderWidth="2px"
																size="lg"
																id="stepFourPropertyDescription"
																name="stepFourPropertyDescription"
																type="text"
																min={50}
															/>
														</InputGroup>
														<FormErrorMessage>
															{
																formik.errors
																	.stepFourPropertyDescription
															}
														</FormErrorMessage>
													</FormControl>
												</Box>
											</Stack>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 5:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<Stack spacing="3">
														<FormLabel
															color="gray"
															mb="3"
														>
															Sales CMA Docs
														</FormLabel>

														{filesLoading ? (
															<Spinner />
														) : (
															<Fragment>
																{stepFiveSalesCmaDocs.map(
																	(
																		doc,
																		index
																	) => (
																		<Button
																			key={
																				index
																			}
																			w="100%"
																			colorScheme="gray"
																			onClick={() => {
																				window.open(
																					doc.fileUrl,
																					'_blank'
																				);
																			}}
																		>
																			<Text>
																				{
																					doc.fileName
																				}{' '}
																				-
																				(
																				{
																					doc.fileType
																				}

																				)
																			</Text>
																		</Button>
																	)
																)}
															</Fragment>
														)}
													</Stack>
												</Box>
											</Stack>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<Stack spacing="3">
														<FormLabel
															color="gray"
															mb="3"
														>
															Appraisal Report
															Docs
														</FormLabel>

														{filesLoading ? (
															<Spinner />
														) : (
															<Fragment>
																{stepFiveAppraisalReportDocs.map(
																	(
																		doc,
																		index
																	) => (
																		<Button
																			key={
																				index
																			}
																			w="100%"
																			colorScheme="gray"
																			onClick={() => {
																				window.open(
																					doc.fileUrl,
																					'_blank'
																				);
																			}}
																		>
																			<Text>
																				{
																					doc.fileName
																				}{' '}
																				-
																				(
																				{
																					doc.fileType
																				}

																				)
																			</Text>
																		</Button>
																	)
																)}
															</Fragment>
														)}
													</Stack>
												</Box>
											</Stack>
										</Box>

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Step 6:
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<Stack spacing="3">
														<FormLabel
															color="gray"
															mb="3"
														>
															Property Images
														</FormLabel>

														{filesLoading ? (
															<Spinner />
														) : (
															<Fragment>
																{stepSixPropertyImages.map(
																	(
																		image,
																		index
																	) => (
																		<Box
																			key={
																				index
																			}
																		>
																			<Text>
																				Image:{' '}
																				{index +
																					1}
																			</Text>
																			<Image
																				maxH="300px"
																				src={
																					image.imageUrl
																				}
																				alt={`Property Image ${index}`}
																			/>
																			<Text>
																				{
																					image.imageDescription
																				}
																			</Text>
																		</Box>
																	)
																)}
															</Fragment>
														)}
													</Stack>
												</Box>
											</Stack>
										</Box>

										{applicationUserData?.type ===
											'agent' && (
											<Box>
												<Text
													fontWeight="600"
													fontSize="lg"
													mb="2"
												>
													Step 7:
												</Text>

												<Stack spacing="3">
													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<FormControl
															isInvalid={
																formik.errors
																	.stepSevenLandlordFirstName &&
																formik.touched
																	.stepSevenLandlordFirstName
															}
														>
															<FormLabel color="gray">
																{
																	selectedProperty
																		?.stepSeven[0]
																		?.question
																}
															</FormLabel>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepSevenLandlordFirstName"
																name="stepSevenLandlordFirstName"
																type="text"
															/>
															<FormErrorMessage>
																{
																	formik
																		.errors
																		.stepSevenLandlordFirstName
																}
															</FormErrorMessage>
														</FormControl>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<FormControl
															isInvalid={
																formik.errors
																	.stepSevenLandlordLastName &&
																formik.touched
																	.stepSevenLandlordLastName
															}
														>
															<FormLabel color="gray">
																{
																	selectedProperty
																		?.stepSeven[1]
																		?.question
																}
															</FormLabel>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepSevenLandlordLastName"
																name="stepSevenLandlordLastName"
																type="text"
															/>
															<FormErrorMessage>
																{
																	formik
																		.errors
																		.stepSevenLandlordLastName
																}
															</FormErrorMessage>
														</FormControl>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<FormControl
															isInvalid={
																formik.errors
																	.stepSevenLandlordEmail &&
																formik.touched
																	.stepSevenLandlordEmail
															}
														>
															<FormLabel color="gray">
																{
																	selectedProperty
																		?.stepSeven[2]
																		?.question
																}
															</FormLabel>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepSevenLandlordEmail"
																name="stepSevenLandlordEmail"
																type="email"
															/>
															<FormErrorMessage>
																{
																	formik
																		.errors
																		.stepSevenLandlordEmail
																}
															</FormErrorMessage>
														</FormControl>
													</Box>

													<Box
														bg="gray.100"
														boxShadow="md"
														p="4"
														rounded="lg"
													>
														<FormControl
															isInvalid={
																formik.errors
																	.stepSevenLandlordPhone &&
																formik.touched
																	.stepSevenLandlordPhone
															}
														>
															<FormLabel color="gray">
																{
																	selectedProperty
																		?.stepSeven[3]
																		?.question
																}
															</FormLabel>
															<Field
																as={Input}
																borderWidth="2px"
																size="lg"
																id="stepSevenLandlordPhone"
																name="stepSevenLandlordPhone"
																type="number"
															/>
															<FormErrorMessage>
																{
																	formik
																		.errors
																		.stepSevenLandlordPhone
																}
															</FormErrorMessage>
														</FormControl>
													</Box>
												</Stack>
											</Box>
										)}

										<Box>
											<Text
												fontWeight="600"
												fontSize="lg"
												mb="2"
											>
												Other Data
											</Text>

											<Stack spacing="3">
												<Box
													bg="gray.100"
													boxShadow="md"
													p="4"
													rounded="lg"
												>
													<Box>
														<FormLabel color="gray">
															Price Per Square
															Foot
														</FormLabel>
														<Text
															fontSize="lg"
															fontWeight="500"
														>
															{(() => {
																const value =
																	selectedProperty
																		?.stepThree[0]
																		?.answer;
																const sqft =
																	selectedProperty
																		?.stepOne[4]
																		?.answer;

																if (
																	!value ||
																	!sqft ||
																	parseFloat(
																		sqft
																	) === 0
																) {
																	return 'N/A';
																}

																const pricePerSqFt =
																	parseFloat(
																		value
																	) /
																	parseFloat(
																		sqft
																	);
																return isFinite(
																	pricePerSqFt
																)
																	? `$ ${pricePerSqFt.toFixed(
																			2
																	  )}`
																	: 'N/A';
															})()}
														</Text>
													</Box>
												</Box>
											</Stack>
										</Box>
									</Stack>
								</ModalBody>

								<ModalFooter>
									<Button
										colorScheme="red"
										mr={3}
										onClick={() => {
											setSelectedProperty(null);
											onClose();
										}}
									>
										Close
									</Button>
									<Button
										colorScheme="blue"
										type="submit"
									>
										Save
									</Button>
								</ModalFooter>
							</ModalContent>
						</form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

export default PropertyModal;
