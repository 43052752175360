import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	agents: [],
	invites: [],
	agentInvites: [],
	clientInvites: [],
	agentClientInvites: [],
	agentLandlordInvites: [],
	dateRange_agents: [],
	dateRange_agentInvites: [],
	dateRange_clientInvites: [],
	dateRange_agentClientInvites: [],
	dateRange_agentLandlordInvites: [],
};

export const UserSlice = createSlice({
	name: 'Agent',
	initialState,
	reducers: {
		resetAgentState: (state) => initialState,
		setAgents: (state, action) => {
			state.agents = action.payload;
		},
		setInvites: (state, action) => {
			state.invites = action.payload;
		},
		setAgentInvites: (state, action) => {
			state.agentInvites = action.payload;
		},
		setClientInvites: (state, action) => {
			state.clientInvites = action.payload;
		},
		setAgentClientInvites: (state, action) => {
			state.agentClientInvites = action.payload;
		},
		setAgentLandlordInvites: (state, action) => {
			state.agentLandlordInvites = action.payload;
		},
		setDateRangeAgents: (state, action) => {
			state.dateRange_agents = action.payload;
		},
		setDateRangeAgentInvites: (state, action) => {
			state.dateRange_agentInvites = action.payload;
		},
		setDateRangeClientInvites: (state, action) => {
			state.dateRange_clientInvites = action.payload;
		},
		setDateRangeAgentClientInvites: (state, action) => {
			state.dateRange_agentClientInvites = action.payload;
		},
		setDateRangeAgentLandlordInvites: (state, action) => {
			state.dateRange_agentLandlordInvites = action.payload;
		},
	},
});

export const {
	resetAgentState,
	setAgents,
	setInvites,
	setAgentInvites,
	setClientInvites,
	setAgentClientInvites,
	setAgentLandlordInvites,
	setDateRangeAgents,
	setDateRangeAgentInvites,
	setDateRangeClientInvites,
	setDateRangeAgentClientInvites,
	setDateRangeAgentLandlordInvites,
} = UserSlice.actions;

export default UserSlice.reducer;
