import * as dayjs from 'dayjs';
import { AGENTS_DATA } from 'components/customTable/makeData';
import {
	collection,
	doc,
	getDocs,
	query,
	setDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import {
	setAgentInvites,
	setAgents,
	setInvites,
	setClientInvites,
	setAgentClientInvites,
	setAgentLandlordInvites,
	setDateRangeAgents,
	setDateRangeAgentInvites,
	setDateRangeClientInvites,
	setDateRangeAgentClientInvites,
	setDateRangeAgentLandlordInvites,
} from 'store/reducers/agentSlice';
import { setMonthlyAgentStats } from 'store/reducers/graphSlice';
import { store } from 'store/store';
import { USER_TYPES } from 'utils/constants';
import { db } from 'utils/firebase';
import {
	convertCommaSeperatedToNormalNumber,
	getApplicationStatus,
} from 'utils/helpers';
import {
	getUserByEmail,
	getUserByEmail_Optimized,
} from './applications.service';
import {
	handleGetUsers,
	handleGetAgents,
	handleGetOffers,
	handleGetApplicationResponses,
	handleGetHomebuyerApplicationResponses,
	handleGetCoapplicantApplicationResponses,
	handleGetAgentApplicationResponses,
	handleGetAgentInvites,
} from './data.service';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

const tz = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);

const getAllAgents = async () => {
	console.log('Running getAllAgents()');
	try {
		let users = [];
		const userRef = collection(db, 'users');

		const docsSnap = await getDocs(userRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();

			if (!data.isDeleted && data?.type === USER_TYPES.AGENT) {
				users.push({
					...data,
					id: doc.id,
					applicationStartDate: data?.applicationStartDate
						? data.applicationStartDate?.toDate()
						: null,
					displayId: doc.data()?.user_id,
					applicationStatus: doc.data()?.applicationCompleted
						? 'Completed'
						: 'InProgress',
					firstName: doc.data().legalName?.firstName,
					lastName: doc.data().legalName?.lastName,
				});
			}
		});

		users = users.sort((a, b) => {
			return b.applicationStartDate - a.applicationStartDate;
		});

		return users;
	} catch (err) {
		console.log('Error getAllAgents(): ', err);
		return [];
	}
};

const getAllAgents_Optimized = async () => {
	console.log('Running getAllAgents_Optimized()');
	try {
		let { users: usersRaw } = store.getState()?.data;

		let users = [];
		for (let i = 0; i < usersRaw.length; i++) {
			const data = usersRaw[i];

			if (!data.isDeleted && data?.type === USER_TYPES.AGENT) {
				users.push({
					...data,
					id: data.id,
					applicationStartDate: data?.applicationStartDate
						? data.applicationStartDate?.toDate()
						: null,
					displayId: data?.user_id,
					applicationStatus: data?.applicationCompleted
						? 'Completed'
						: 'InProgress',
					firstName: data.legalName?.firstName,
					lastName: data.legalName?.lastName,
				});
			}
		}

		users = users.sort((a, b) => {
			return b.applicationStartDate - a.applicationStartDate;
		});

		return users;
	} catch (err) {
		console.log('Error getAllAgents_Optimized(): ', err);
		return [];
	}
};

const getAllAgentsData = async () => {
	console.log('Running getAllAgentsData()');
	try {
		const users = await getAllAgents_Optimized();
		const applications = await getAllAgentApplications_Optimized();

		let agentsAll = [];

		let allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const rData = {
				...user,
				name:
					user?.legalName?.firstName +
					' ' +
					user?.legalName?.lastName,
				application: application ? application : null,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				phoneNumber: application?.stepOne[0]?.answer,
				submissionDate: application?.timeSubmitted
					? application.timeSubmitted?.toDate()
					: null,
				createdAt: user?.account?.accountCreated
					? user?.account?.accountCreated?.toDate()
					: null,
			};

			agentsAll.push(rData);

			return rData;
		});

		allData = allData.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		store.dispatch(setAgents(allData));

		return allData;
	} catch (err) {
		console.log('Error getAllAgentsData(): ', err);
		return [];
	}
};

const getAllAgentsDataByDateRange = async () => {
	console.log('Running getAllAgentsDataByDateRange()');
	try {
		let { fromDate, toDate } = store.getState()?.graph;

		const users = await getAllAgents_Optimized();
		const applications = await getAllAgentApplications_Optimized();

		let startDate = dayjs(fromDate).tz(tz, true).toDate();
		let endDate = dayjs(toDate).tz(tz, true).toDate();

		let agentsAll = [];

		let allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const rData = {
				...user,
				name:
					user?.legalName?.firstName +
					' ' +
					user?.legalName?.lastName,
				application: application ? application : null,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				phoneNumber: application?.stepOne[0]?.answer,
				submissionDate: application?.timeSubmitted
					? application.timeSubmitted?.toDate()
					: null,
				createdAt: user?.account?.accountCreated
					? user?.account?.accountCreated?.toDate()
					: null,
			};

			agentsAll.push(rData);

			return rData;
		});

		agentsAll = agentsAll.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		let dateRange_agentsAll = [];
		while (startDate <= endDate) {
			const numAgentsInDateRange = agentsAll.filter((user) => {
				return (
					(user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
						user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
						user?.applicationStartDate?.getFullYear() ===
							startDate.getFullYear()) ||
					(user?.createdAt?.getDate() === startDate.getDate() &&
						user?.createdAt?.getMonth() === startDate.getMonth() &&
						user?.createdAt?.getFullYear() ===
							startDate.getFullYear())
				);
			});
			for (let i = 0; i < numAgentsInDateRange.length; i++) {
				dateRange_agentsAll.push(numAgentsInDateRange[i]);
			}

			startDate.setDate(startDate.getDate() + 1);
		}

		store.dispatch(setDateRangeAgents(dateRange_agentsAll));

		return allData;
	} catch (err) {
		console.log('Error getAllAgentsDataByDateRange(): ', err);
		return [];
	}
};

const getAllAgentApplications = async () => {
	console.log('Running getAllAgentApplications()');
	try {
		let applications = [];
		const userRef = collection(db, 'agent_application_responses');
		const docsSnap = await getDocs(userRef);
		docsSnap.forEach((doc) => {
			applications.push({
				...doc.data(),
			});
		});

		return applications;
	} catch (err) {
		console.log('Error getAllAgentApplications(): ', err);
		return [];
	}
};

const getAllAgentApplications_Optimized = async () => {
	console.log('Running getAllAgentApplications_Optimized()');
	try {
		let applications = [];

		let { agentApplicationResponses: agentApplicationResponsesRaw } =
			store.getState()?.data;
		for (let i = 0; i < agentApplicationResponsesRaw.length; i++) {
			const data = agentApplicationResponsesRaw[i];

			applications.push({
				...data,
			});
		}

		return applications;
	} catch (err) {
		console.log('Error getAllAgentApplications_Optimized(): ', err);
		return [];
	}
};

const updateAgentApplicationResponseFields = async (
	updatedField,
	applicationId
) => {
	console.log('Running updateAgentApplicationResponseFields()');
	try {
		const docRef = doc(
			db,
			'agent_application_responses',
			applicationId
		);
		await setDoc(docRef, updatedField, {
			merge: true,
		});

		await handleGetAgentApplicationResponses();
		await getAllAgentsData();
	} catch (err) {
		console.log(
			'Error updateAgentApplicationResponseFields(): ',
			err
		);
		return null;
	}
};

const changeAgentStatusToDeleted = async (agentId) => {
	console.log('Running changeAgentStatusToDeleted()');
	try {
		const docRef = doc(db, 'agents', agentId);
		setDoc(
			docRef,
			{
				isDeleted: true,
			},
			{
				merge: true,
			}
		);

		const otherRef = doc(db, 'users', agentId);
		setDoc(
			otherRef,
			{
				isDeleted: true,
			},
			{
				merge: true,
			}
		);

		await handleGetAgents();
		await handleGetUsers();
	} catch (err) {
		console.log('Error changeAgentStatusToDeleted(): ', err);
		return null;
	}
};

const getUsersByEmails = async (emails) => {
	console.log('Running getUsersByEmails()');
	try {
		const userRef = collection(db, 'users');
		const userQuery = query(userRef, where('email', 'in', emails));
		const querySnapshot = await getDocs(userQuery);

		return querySnapshot.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));
	} catch (err) {
		console.log('Error getUsersByEmails(): ', err);
		return [];
	}
};

const getUsersByEmails_Optimized = async (emails) => {
	console.log('Running getUsersByEmails_Optimized()');
	try {
		let { users: usersRaw } = store.getState()?.data;
		let users = [];

		for (let i = 0; i < usersRaw.length; i++) {
			const data = usersRaw[i];

			if (emails.includes(data.email)) {
				users.push({
					...data,
				});
			}
		}

		return users;
	} catch (err) {
		console.log('Error getUsersByEmails_Optimized(): ', err);
		return [];
	}
};

const updateNameInInvites = async () => {
	console.log('Running updateNameInInvites()');
	try {
		const inviteRef = collection(db, 'agent_invites');
		const emptyNameQuery = query(
			inviteRef,
			where('clientName', '==', ' ')
		);

		const emptyNameSnap = await getDocs(emptyNameQuery);

		const clientEmails = emptyNameSnap.docs.map(
			(doc) => doc.data().clientEmail
		);

		const userEmailsInBatch = 10;
		const emailUserBatches = [];

		for (let i = 0; i < clientEmails.length; i += userEmailsInBatch) {
			const batch = clientEmails.slice(i, i + userEmailsInBatch);
			const users = await getUsersByEmails_Optimized(batch);
			emailUserBatches.push(...users);
		}

		const emailUserMap = new Map(
			emailUserBatches.map((user) => [user.email, user])
		);

		const batch = writeBatch(db);

		emptyNameSnap.docs.forEach((doc) => {
			const data = doc.data();
			const emailUser = emailUserMap.get(data.clientEmail);

			if (emailUser?.legalName) {
				const clientName =
					emailUser.legalName.firstName +
					' ' +
					emailUser.legalName.lastName;

				batch.update(doc.ref, {
					status: data.status,
					clientName: clientName,
				});
			}
		});

		await batch.commit();
	} catch (err) {
		console.log('Error updateNameInInvites(): ', err);
		return null;
	}
};

const getAllInvites = async () => {
	console.log('Running getAllInvites()');
	try {
		let invites = [];

		const inviteRef = collection(db, 'agent_invites');
		const docsSnap = await getDocs(inviteRef);

		docsSnap.forEach(async (doc) => {
			const data = doc.data();

			const currentStatus = data?.status;

			const statusName = getApplicationStatus(currentStatus);

			invites.push({
				...data,
				uid: doc.id,
				type: Boolean(data.referral) ? 'Link' : 'Email',
				status: statusName,
			});
		});

		return invites;
	} catch (err) {
		console.log('Error getAllInvites(): ', err);
		return [];
	}
};

const getAllInvites_Optimized = async () => {
	console.log('Running getAllInvites_Optimized()');
	try {
		let { agentInvites: agentInvitesRaw } = store.getState()?.data;

		let invites = [];

		for (let i = 0; i < agentInvitesRaw.length; i++) {
			const data = agentInvitesRaw[i];

			const currentStatus = data?.status;
			const statusName = getApplicationStatus(currentStatus);

			invites.push({
				...data,
				uid: data.id,
				type: Boolean(data.referral) ? 'Link' : 'Email',
				status: statusName,
			});
		}

		return invites;
	} catch (err) {
		console.log('Error getAllInvites_Optimized(): ', err);
		return [];
	}
};

// const getAgentInvites = async () => {
//   const invites = await getAllInvites();
//   console.log(
//     "🚀 ~ file: agents.service.js:155 ~ getAgentInvites ~ invites:",
//     invites
//   );

//   let finalPayload = [],
//     agentInvites = [];

//   for (let invite of invites) {
//     if (!invite.isDeleted) {
//       const agentInfo = await getAgentById(invite.agent);

//       let payload = {
//         ...invite,
//         name:
//           agentInfo?.legalName?.firstName +
//           " " +
//           agentInfo?.legalName?.lastName,
//         email: agentInfo.email,
//         createdAt: invite.createdAt?.toDate(),
//       };

//       if (invite.isAgent) {
//         agentInvites.push(payload);
//       } else {
//         finalPayload.push(payload);
//       }
//     }
//   }

//   finalPayload = finalPayload.sort((a, b) => {
//     return b.createdAt - a.createdAt;
//   });
//   store.dispatch(setInvites(finalPayload));

//   agentInvites = agentInvites.sort((a, b) => {
//     return b.createdAt - a.createdAt;
//   });
//   console.log(
//     "🚀 ~ file: agents.service.js:190 ~ agentInvites=agentInvites.sort ~ agentInvites:",
//     agentInvites
//   );

//   store.dispatch(setAgentInvites(agentInvites));

//   return invites;
// };

const getAgentInvites = async () => {
	console.log('Running getAgentInvites()');
	try {
		const invites = await getAllInvites_Optimized();

		let finalPayload = [],
			agentInvites = [],
			clientInvites = [],
			landlordInvites = [];

		const agentIds = Array.from(
			new Set(invites.map((invite) => invite.agent))
		);

		const agentIdBatches = [];
		for (let i = 0; i < agentIds.length; i += 10) {
			agentIdBatches.push(agentIds.slice(i, i + 10));
		}

		const agentDocs = [];

		for (let agentIdBatch of agentIdBatches) {
			let index = agentIdBatch.indexOf(null);
			if (index > -1) {
				agentIdBatch.splice(index, 1);
			}

			const docs = await getDocs(
				query(
					collection(db, 'users'),
					where('__name__', 'in', agentIdBatch)
				)
			);
			agentDocs.push(...docs.docs);
		}

		const agentMap = agentDocs.reduce((map, doc) => {
			map[doc.id] = doc.data();
			return map;
		}, {});

		for (let invite of invites) {
			if (!invite.isDeleted) {
				const agentInfo = agentMap[invite.agent];

				let payload = {
					...invite,
					name:
						agentInfo?.legalName?.firstName +
						' ' +
						agentInfo?.legalName?.lastName,
					email: agentInfo?.email,
					createdAt: invite.createdAt?.toDate(),

					status: invite.status,
				};

				if (invite.isAgent) {
					agentInvites.push(payload);
				} else if (invite.isClientInvite) {
					clientInvites.push(payload);
				} else if (
					invite.userType === 'LANDLORD' ||
					invite.userType === 'landlord'
				) {
					landlordInvites.push(payload);
				} else {
					finalPayload.push(payload);
				}
			}
		}

		finalPayload = finalPayload.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		store.dispatch(setInvites(finalPayload));

		agentInvites = agentInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		clientInvites = clientInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		landlordInvites = landlordInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		store.dispatch(setAgentInvites(agentInvites));
		store.dispatch(setClientInvites(clientInvites));
		store.dispatch(setAgentClientInvites(finalPayload));
		store.dispatch(setAgentLandlordInvites(landlordInvites));

		return { finalPayload, agentInvites };
	} catch (err) {
		console.log('Error getAgentInvites(): ', err);

		const finalPayload = [];
		const agentInvites = [];
		return { finalPayload, agentInvites };
	}
};

const getAgentInvitesByDateRange = async () => {
	console.log('Running getAgentInvitesByDateRange()');
	try {
		let { fromDate, toDate } = store.getState()?.graph;

		const invites = await getAllInvites_Optimized();

		let startDate = dayjs(fromDate).tz(tz, true).toDate();
		let endDate = dayjs(toDate).tz(tz, true).toDate();

		let finalPayload = [],
			agentInvites = [],
			clientInvites = [],
			landlordInvites = [];

		const agentIds = Array.from(
			new Set(invites.map((invite) => invite.agent))
		);

		const agentIdBatches = [];
		for (let i = 0; i < agentIds.length; i += 10) {
			agentIdBatches.push(agentIds.slice(i, i + 10));
		}

		const agentDocs = [];

		for (let agentIdBatch of agentIdBatches) {
			let index = agentIdBatch.indexOf(null);
			if (index > -1) {
				agentIdBatch.splice(index, 1);
			}

			const docs = await getDocs(
				query(
					collection(db, 'users'),
					where('__name__', 'in', agentIdBatch)
				)
			);
			agentDocs.push(...docs.docs);
		}

		const agentMap = agentDocs.reduce((map, doc) => {
			map[doc.id] = doc.data();
			return map;
		}, {});

		for (let invite of invites) {
			if (!invite.isDeleted) {
				const agentInfo = agentMap[invite.agent];

				let payload = {
					...invite,
					name:
						agentInfo?.legalName?.firstName +
						' ' +
						agentInfo?.legalName?.lastName,
					email: agentInfo?.email,
					createdAt: invite.createdAt?.toDate(),

					status: invite.status,
				};

				if (invite.isAgent) {
					agentInvites.push(payload);
				} else if (invite.isClientInvite) {
					clientInvites.push(payload);
				} else if (
					invite.userType === 'LANDLORD' ||
					invite.userType === 'landlord'
				) {
					landlordInvites.push(payload);
				} else {
					finalPayload.push(payload);
				}
			}
		}

		finalPayload = finalPayload.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		store.dispatch(setInvites(finalPayload));

		agentInvites = agentInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		clientInvites = clientInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		landlordInvites = landlordInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		let dateRange_agentInvites = [];
		let dateRange_clientInvites = [];
		let dateRange_finalPayload = [];
		let dateRange_landlordInvites = [];
		while (startDate <= endDate) {
			const numAgentInviteInDateRange = agentInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
							user?.applicationStartDate?.getMonth() ===
								startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getDate() === startDate.getDate() &&
							user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);
			for (let i = 0; i < numAgentInviteInDateRange.length; i++) {
				dateRange_agentInvites.push(numAgentInviteInDateRange[i]);
			}

			const numClientInviteInDateRange = clientInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
							user?.applicationStartDate?.getMonth() ===
								startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getDate() === startDate.getDate() &&
							user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);
			for (let i = 0; i < numClientInviteInDateRange.length; i++) {
				dateRange_clientInvites.push(numClientInviteInDateRange[i]);
			}

			const numLandlordInviteInDateRange = landlordInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
							user?.applicationStartDate?.getMonth() ===
								startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getDate() === startDate.getDate() &&
							user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);
			for (let i = 0; i < numLandlordInviteInDateRange.length; i++) {
				dateRange_landlordInvites.push(
					numLandlordInviteInDateRange[i]
				);
			}

			const numFinalInviteInDateRange = finalPayload.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
							user?.applicationStartDate?.getMonth() ===
								startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getDate() === startDate.getDate() &&
							user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);
			for (let i = 0; i < numFinalInviteInDateRange.length; i++) {
				dateRange_finalPayload.push(numFinalInviteInDateRange[i]);
			}

			startDate.setDate(startDate.getDate() + 1);
		}

		store.dispatch(setDateRangeAgentInvites(dateRange_agentInvites));
		store.dispatch(
			setDateRangeClientInvites(dateRange_clientInvites)
		);
		store.dispatch(
			setDateRangeAgentClientInvites(dateRange_finalPayload)
		);
		store.dispatch(
			setDateRangeAgentLandlordInvites(dateRange_landlordInvites)
		);

		return { dateRange_finalPayload, dateRange_agentInvites };
	} catch (err) {
		console.log('Error getAgentInvitesByDateRange(): ', err);

		const dateRange_finalPayload = [];
		const dateRange_agentInvites = [];
		return { dateRange_finalPayload, dateRange_agentInvites };
	}
};

const changeInviteStatusToDeleted = async (inviteId) => {
	console.log('Running changeInviteStatusToDeleted()');
	try {
		const docRef = doc(db, 'agent_invites', inviteId);
		await setDoc(
			docRef,
			{
				isDeleted: true,
			},
			{
				merge: true,
			}
		);

		await handleGetAgentInvites();
	} catch (err) {
		console.log('Error changeInviteStatusToDeleted(): ', err);
		return null;
	}
};

const getAgentByEmail = async (email) => {
	console.log('Running getAgentByEmail()');
	try {
		const userRef = collection(db, 'agents');
		const userQuery = query(userRef, where('email', '==', email));
		const querySnapshot = await getDocs(userQuery);

		let res;

		if (querySnapshot.docs.length) {
			res = {
				...querySnapshot.docs[0]?.data(),
				id: querySnapshot.docs[0].id,
			};
		}

		return res;
	} catch (err) {
		console.log('Error getAgentByEmail(): ', err);
		return [];
	}
};

const getAgentByEmail_Optimized = async (email) => {
	console.log('Running getAgentByEmail_Optimized()');
	try {
		let { agents: agentsRaw } = store.getState()?.data;

		let agents = [];

		for (let i = 0; i < agentsRaw.length; i++) {
			const data = agentsRaw[i];

			if (email === data.email) {
				agents.push({
					...data,
				});
			}
		}

		return agents[0];
	} catch (err) {
		console.log('Error getAgentByEmail_Optimized(): ', err);
		return null;
	}
};

const checkAgentsInDB = async () => {
	console.log('Running checkAgentsInDB()');
	try {
		let updatedData = [];

		let count = 0;
		for (let agent of AGENTS_DATA) {
			let agentDetails = await getUserByEmail_Optimized(agent.email);
			if (!agent) {
				agentDetails = await getAgentByEmail_Optimized(agent.email);
			}

			if (agentDetails) {
				if (agentDetails.type !== USER_TYPES.AGENT) {
					const docRef = doc(db, 'users', agentDetails.id);
					await setDoc(
						docRef,
						{
							type: USER_TYPES.AGENT,
						},
						{
							merge: true,
						}
					);
					count++;
				}
			}

			updatedData.push({
				...agent,
				status: agentDetails ? 'handled' : 'never signed up',
			});
		}

		await handleGetUsers();

		navigator.clipboard.writeText(updatedData);
	} catch (err) {
		console.log('Error checkAgentsInDB(): ', err);
		return null;
	}
};

const getMonthlyAgentStats = async (
	year = new Date().getFullYear()
) => {
	console.log('Running getMonthlyAgentStats()');
	try {
		const users = await getAllAgents_Optimized();
		const applications = await getAllAgentApplications_Optimized();

		let startDate = new Date(year, 0, 1);
		let endDate = new Date(year, 11, 31);

		let categories = [];

		let agentsAll = [];

		let allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const rData = {
				...user,
				name:
					user?.legalName?.firstName +
					' ' +
					user?.legalName?.lastName,
				application: application ? application : null,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				phoneNumber: application?.stepOne[0]?.answer,
				submissionDate: application?.timeSubmitted
					? application.timeSubmitted?.toDate()
					: null,
				createdAt: user?.account?.accountCreated
					? user?.account?.accountCreated?.toDate()
					: null,
			};

			agentsAll.push(rData);

			return rData;
		});

		allData = allData.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		let agentsData = [];
		while (startDate <= endDate) {
			const month = startDate.toLocaleString('default', {
				month: 'long',
			});

			const numAgentsInCurrentMonth = agentsAll.filter((user) => {
				return (
					(user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
						user?.applicationStartDate?.getFullYear() ===
							startDate.getFullYear()) ||
					(user?.createdAt?.getMonth() === startDate.getMonth() &&
						user?.createdAt?.getFullYear() ===
							startDate.getFullYear())
				);
			});

			categories.push(`${month}, ${year.toString().slice(-2)}`);
			agentsData.push(numAgentsInCurrentMonth.length);

			startDate.setMonth(startDate.getMonth() + 1);
		}

		return { categories, agentsData };
	} catch (err) {
		console.log('Error getMonthlyAgentStats(): ', err);
	}
};

const getMonthlyInviteStats = async (
	year = new Date().getFullYear()
) => {
	console.log('Running getMonthlyInviteStats()');
	try {
		const invites = await getAllInvites_Optimized();

		let startDate = new Date(year, 0, 1);
		let endDate = new Date(year, 11, 31);

		let categories = [];

		let finalPayload = [],
			agentInvites = [],
			clientInvites = [],
			landlordInvites = [];

		const agentIds = Array.from(
			new Set(invites.map((invite) => invite.agent))
		);

		const agentIdBatches = [];
		for (let i = 0; i < agentIds.length; i += 10) {
			agentIdBatches.push(agentIds.slice(i, i + 10));
		}

		const agentDocs = [];

		for (let agentIdBatch of agentIdBatches) {
			let index = agentIdBatch.indexOf(null);
			if (index > -1) {
				agentIdBatch.splice(index, 1);
			}

			const docs = await getDocs(
				query(
					collection(db, 'users'),
					where('__name__', 'in', agentIdBatch)
				)
			);
			agentDocs.push(...docs.docs);
		}

		const agentMap = agentDocs.reduce((map, doc) => {
			map[doc.id] = doc.data();
			return map;
		}, {});

		for (let invite of invites) {
			if (!invite.isDeleted) {
				const agentInfo = agentMap[invite.agent];

				let payload = {
					...invite,
					name:
						agentInfo?.legalName?.firstName +
						' ' +
						agentInfo?.legalName?.lastName,
					email: agentInfo?.email,
					createdAt: invite.createdAt?.toDate(),

					status: invite.status,
				};

				if (invite.isAgent) {
					agentInvites.push(payload);
				} else if (invite.isClientInvite) {
					clientInvites.push(payload);
				} else if (
					invite.userType === 'LANDLORD' ||
					invite.userType === 'landlord'
				) {
					landlordInvites.push(payload);
				} else {
					finalPayload.push(payload);
				}
			}
		}

		finalPayload = finalPayload.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		agentInvites = agentInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		landlordInvites = landlordInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});
		clientInvites = clientInvites.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		let agentInvitesData = [];
		let clientInvitesData = [];
		let landlordInvitesData = [];
		let finalPayloadData = [];
		while (startDate <= endDate) {
			const month = startDate.toLocaleString('default', {
				month: 'long',
			});

			const numAgentsInvitesInCurrentMonth = agentInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);

			const numClientsInvitesInCurrentMonth = clientInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);

			const numLandlordInvitesInCurrentMonth = landlordInvites.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);

			const numFinalsInvitesInCurrentMonth = finalPayload.filter(
				(user) => {
					return (
						(user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
							user?.applicationStartDate?.getFullYear() ===
								startDate.getFullYear()) ||
						(user?.createdAt?.getMonth() === startDate.getMonth() &&
							user?.createdAt?.getFullYear() ===
								startDate.getFullYear())
					);
				}
			);

			categories.push(`${month}, ${year.toString().slice(-2)}`);
			agentInvitesData.push(numAgentsInvitesInCurrentMonth.length);
			finalPayloadData.push(numFinalsInvitesInCurrentMonth.length);
			clientInvitesData.push(numClientsInvitesInCurrentMonth.length);
			landlordInvitesData.push(
				numLandlordInvitesInCurrentMonth.length
			);
			startDate.setMonth(startDate.getMonth() + 1);
		}

		return {
			agentInvitesData,
			finalPayloadData,
			clientInvitesData,
			landlordInvitesData,
		};
	} catch (err) {
		console.log('Error getMonthlyInviteStats(): ', err);
	}
};

const getMonthlyAgentAndInviteStats = async (
	year = new Date().getFullYear()
) => {
	console.log('Running getMonthlyAgentAndInviteStats()');
	try {
		const agentData = await getMonthlyAgentStats();
		const inviteData = await getMonthlyInviteStats();

		store.dispatch(
			setMonthlyAgentStats({
				agents: {
					categories: agentData?.categories,
					chartData: agentData?.agentsData,
				},
				agentInvited: {
					categories: agentData?.categories,
					chartData: inviteData?.agentInvitesData,
				},
				clientInvites: {
					categories: agentData?.categories,
					chartData: inviteData?.finalPayloadData,
				},
				clientClientInvites: {
					categories: agentData?.categories,
					chartData: inviteData?.clientInvitesData,
				},
			})
		);
	} catch (err) {
		console.log('Error getMonthlyAgentAndInviteStats(): ', err);
	}
};

export {
	changeAgentStatusToDeleted,
	changeInviteStatusToDeleted,
	getAgentInvites,
	getAllAgentsData,
	updateAgentApplicationResponseFields,
	updateNameInInvites,
	getAgentInvitesByDateRange,
	getAllAgentsDataByDateRange,
	getMonthlyAgentAndInviteStats,
};
