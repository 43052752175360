import { store } from 'store/store';
import {
	resetOfferState,
	setUsers,
	setAgents,
	setOffers,
	setApplicationResponses,
	setHomebuyerApplicationResponses,
	setCoapplicantApplicationResponses,
	setAgentApplicationResponses,
	setAgentInvites,
} from 'store/reducers/dataSlice';

// firebase
import app from '../utils/firebase';
import {
	Timestamp,
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
	updateDoc,
	addDoc,
	getFirestore,
} from 'firebase/firestore';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';

const handleGetUsers = async () => {
	console.log('Running handleGetUsers()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'users');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setUsers(dataList));
	} catch (err) {
		console.log('Error handleGetUsers(): ', err);
	}
};

const handleGetAgents = async () => {
	console.log('Running handleGetAgents()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'agents');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setAgents(dataList));
	} catch (err) {
		console.log('Error handleGetAgents(): ', err);
	}
};

const handleGetOffers = async () => {
	console.log('Running handleGetOffers()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'offers');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setOffers(dataList));
	} catch (err) {
		console.log('Error handleGetOffers(): ', err);
	}
};

const handleGetApplicationResponses = async () => {
	console.log('Running handleGetApplicationResponses()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'application_responses');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setApplicationResponses(dataList));
	} catch (err) {
		console.log('Error handleGetApplicationResponses(): ', err);
	}
};

const handleGetHomebuyerApplicationResponses = async () => {
	console.log('Running handleGetHomebuyerApplicationResponses()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'homebuyer_application_responses');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setHomebuyerApplicationResponses(dataList));
	} catch (err) {
		console.log('Error handleGetHomebuyerApplicationResponses(): ', err);
	}
};

const handleGetCoapplicantApplicationResponses = async () => {
	console.log('Running handleGetCoapplicantApplicationResponses()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'coapplicant_application_responses');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setCoapplicantApplicationResponses(dataList));
	} catch (err) {
		console.log('Error handleGetCoapplicantApplicationResponses(): ', err);
	}
};

const handleGetAgentApplicationResponses = async () => {
	console.log('Running handleGetAgentApplicationResponses()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'agent_application_responses');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const id = doc.id;
			dataList.push({ id: id, ...data });
		});

		store.dispatch(setAgentApplicationResponses(dataList));
	} catch (err) {
		console.log('Error handleGetAgentApplicationResponses(): ', err);
	}
};

const handleGetAgentInvites = async () => {
	console.log('Running handleGetAgentInvites()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'agent_invites');
		const docsSnap = await getDocs(docsRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();
			const ref = doc.ref;
			const id = doc.id;
			dataList.push({ id: id, ref: ref, ...data });
		});

		store.dispatch(setAgentInvites(dataList));
	} catch (err) {
		console.log('Error handleGetAgentInvites(): ', err);
	}
};

const handleGetInitiatedProperties = async () => {
	console.log('Running handleGetInitiatedProperties()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'property_interest');
		const docsSnap = await getDocs(docsRef);

		for (const document of docsSnap.docs) {
			const data = document.data();
			const id = document.id;

			const userId = data.userId;
			const propertyId = data.propertyId;
			const listerId = data.listerId;

			if (!userId || !propertyId || !listerId) {
				console.warn(`Skipping document ${id} due to missing ID`, {
					userId,
					propertyId,
					listerId,
				});
				continue;
			}

			let userData = {},
				listerData = {},
				propertyData = {};

			try {
				// Get user data from users table
				const userDocRef = doc(db, 'users', userId);
				const userDocSnap = await getDoc(userDocRef);
				userData = userDocSnap.data() || {};
			} catch (error) {
				console.error(`Error fetching user data for ${userId}:`, error);
			}

			// try {
			// 	// Get lister data from users table
			// 	const listerDocRef = doc(db, 'users', listerId);
			// 	const listerDocSnap = await getDoc(listerDocRef);
			// 	listerData = listerDocSnap.data() || {};
			// } catch (error) {
			// 	console.error(
			// 		`Error fetching lister data for ${listerId}:`,
			// 		error
			// 	);
			// }

			try {
				// Get property data from properties table
				const propertyDocRef = doc(
					db,
					'landlord_properties',
					propertyId
				);
				const propertyDocSnap = await getDoc(propertyDocRef);
				propertyData = propertyDocSnap.data() || {};
			} catch (error) {
				console.error(
					`Error fetching property data for ${propertyId}:`,
					error
				);
			}

			dataList.push({
				id,
				...data,
				userData,
				listerData,
				propertyData,
			});
		}

		return dataList;
	} catch (err) {
		console.log('Error handleGetInitiatedProperties(): ', err);
		return []; // Return an empty array in case of error
	}
};

const handleGetInitiatedPropertiesByUserId = async (userId) => {
	console.log('Running handleGetInitiatedPropertiesByUserId()');
	try {
		const db = getFirestore(app);
		const dataList = [];

		const docsRef = collection(db, 'property_interest');
		const q = query(docsRef, where('userId', '==', userId));
		const docsSnap = await getDocs(q);

		for (const document of docsSnap.docs) {
			const data = document.data();
			const id = document.id;

			const userId = data.userId;
			const propertyId = data.propertyId;
			const listerId = data.listerId;

			if (!userId || !propertyId || !listerId) {
				console.warn(`Skipping document ${id} due to missing ID`, {
					userId,
					propertyId,
					listerId,
				});
				continue;
			}

			let userData = {},
				listerData = {},
				propertyData = {};

			try {
				// Get user data from users table
				const userDocRef = doc(db, 'users', userId);
				const userDocSnap = await getDoc(userDocRef);
				userData = userDocSnap.data() || {};
			} catch (error) {
				console.error(`Error fetching user data for ${userId}:`, error);
			}

			try {
				// Get property data from properties table
				const propertyDocRef = doc(
					db,
					'landlord_properties',
					propertyId
				);
				const propertyDocSnap = await getDoc(propertyDocRef);
				propertyData = propertyDocSnap.data() || {};
			} catch (error) {
				console.error(
					`Error fetching property data for ${propertyId}:`,
					error
				);
			}

			dataList.push({
				id,
				...data,
				userData,
				listerData,
				propertyData,
			});
		}

		return dataList;
	} catch (err) {
		console.log('Error handleGetInitiatedPropertiesByUserId(): ', err);
		return []; // Return an empty array in case of error
	}
};

export {
	handleGetUsers,
	handleGetAgents,
	handleGetOffers,
	handleGetApplicationResponses,
	handleGetHomebuyerApplicationResponses,
	handleGetCoapplicantApplicationResponses,
	handleGetAgentApplicationResponses,
	handleGetAgentInvites,
	handleGetInitiatedProperties,
	handleGetInitiatedPropertiesByUserId,
};
